<template>
    <div class="car_purpose modal fade-up2">
        <div class="car_purpose_box modal_box">
            <div class="car_purpose_title modal_title">
                <h1>지금은 어떤 목적으로 차량을 이용하시나요?</h1>
            </div>
            <div class="types2 fade-up2">
                <div class="">
                    <ul class="acodian_tab" @mouseover.once="selectAcodian">
                        <li v-for="(item, i) in purposeList" :key="i" :class="item.carPurposeNm === '출근' ? 'gowork'
                                : item.carPurposeNm === '퇴근' ? 'gohome'
                                    : item.carPurposeNm === '근거리 드라이브' ? 'short_drive'
                                        : item.carPurposeNm === '장거리 운행' ? 'long_drive'
                                            : item.carPurposeNm === '업무중' ? 'working'
                                                : item.carPurposeNm === '일상생활' ? 'routain' : 'travel'"
                            @click="selectTab(item.carPurposeNm, item.no)">
                            <p>{{ item.carPurposeNm }}</p>
                            <div></div>
                        </li>
                    </ul>
                </div>
                <div class="confirm2">
                    <div>
                        <div class="agree_box2" v-show="carPurposeCheck == null" @click="changeImg">
                            <img id="agree_check" :src="'/media/img/login_check_' + imgPath + '.png'" alt="">
                            <p>플레이어 이용시마다 이용 목적 묻기</p>
                        </div>
                    </div>
                    <div class="popup_bottom modal_bottom_btn">
                        <button @click="updateCarCurationStatus" :disabled="btndisabled ? false : true"
                                :class="btndisabled ? 'disableFalse' : 'disableTrue'">확인</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from 'axios';
import $ from 'jquery';
export default {
  name: 'carCuration',

  props: {
    carPurposeCheck: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      // 전체 이용 목적
      purposeList: [],
      carPurpose: null,

      imgPath: 'active',
      defaultURL: 'https://www.aplayz.co.kr/media/upload/mentForCarUsePurpose/',
      cheerInfo: null,
      tabName: '',
      loading: false
    };
  },

  created () {
    this.getCarPurpose();
  },

  mounted () {
    window.addEventListener('resize', this.changeStyle);

    if (this.carPurposeCheck != null) {
      this.imgPath = this.carPurposeCheck === 'Y' ? this.imgPath = 'active' : this.imgPath = 'off';
    }
  },

  methods: {
    getCarPurpose () {
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      axios
        .post('/api/profile/getCarPurposeInfo', { headers })
        .then(res => {
          if (res.data.resultCd === '0000') this.purposeList = res.data.result;
        })
        .catch(e => { console.log(e); });
    },

    changeStyle () {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      if (document.querySelector(".car_purpose_box")) {
        if (windowWidth > 500) {
          document.querySelector(".car_purpose_box").style.height = '485px';
        } else {
          windowHeight >= 615 ? document.querySelector(".car_purpose_box").style.height = window.innerHeight + "px" : document.querySelector(".car_purpose_box").style.height = "615px";
        }
      }
    },

    changeImg () { // 이용목적 버튼 이미지 변경
      this.imgPath = this.imgPath === 'off' ? this.imgPath = 'active' : this.imgPath = 'off';
    },

    selectAcodian () {
      $(".acodian_tab > li").click(function () {
        $(".acodian_tab > li").children('div').removeClass('on');
        $(this).children('div').toggleClass('on');
      });
    },

    selectTab (item, no) {
      this.cheerInfo = {
        albumImg: '',
        artist: '',
        musicNm: '',
        src: this.defaultURL + item + '.mp3',
        musicType: 'brand' // 좋아요 / 싫어요 숨기기
      };
      this.carPurpose = no;
    },

    async updateCarCurationStatus () { // 이용목적 묻기 정보 업데이트
      this.loading = true;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      const spaceId = VueCookies.get('spaceId');
      const carPurposeCheck = this.imgPath === 'off' ? 'N' : 'Y';
      const carPurpose = this.carPurpose;

      if (this.carPurposeCheck !== carPurposeCheck) { // 이용목적 값이 변하지 않았을 경우
        await axios
          .post('/api/profile/updateCurationInfo', { spaceId, carPurposeCheck }, { headers })
          .then(() => {
          })
          .catch();
      }
      if (this.loading) {
        await axios
          .post('/api/profile/insertCarPurpose', { spaceId, carPurpose })
          .then(async res => {
            if (res.data.resultCd === '0000') {
              this.$emit('closePopup', this.cheerInfo);
            }
          })
          .catch(e => { console.log(e); })

          .finally(() => {
            this.loading = false;
          });
      }
    },

    getCarPurPoseVideo () {
      return document.getElementById("car_purpose_video");
    },
    carPurPosePlayMusic (url) {
      const video = this.getCarPurPoseVideo();
      video.src = url;
      video.muted = false;
      // 플레이
      video.play();
    }
  },

  computed: {
    btndisabled () {
      return this.cheerInfo !== null && !this.loading;
    }
  }
};
</script>

<style scoped>
.modal {
  display: block;
}

.car_purpose {
  background: #000;
}

.car_purpose_box {
  width: 90%;
  height: 485px;
  max-width: 540px;
  padding: 32px 32px 16px;
  overflow: hidden;
}

.car_purpose_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.car_purpose_title h1 {
  color: #fff;
  font-weight: 500;
  font-size: 24px;
}

.car_purpose_title img {
  cursor: pointer;
}

.types2 {
  width: 100%;
}

.types2>div ul {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 5%;
  margin-bottom: 0;
}

.types2>div ul li {
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 64px;
  border-radius: 30%;
  cursor: pointer;
  background: rgba(225, 225, 225 0.16);
}

.types2>div ul li:nth-child(4) {
  margin-right: 0;
}

.types2>div ul li div {
  opacity: 0;
  width: 100%;
  height: 100%;
  border-radius: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 30px 20px rgba(0, 0, 0, 0.24);
  transition: all 0.3s;
}

.types2>div ul li:hover div {
  opacity: 1;
}

.types2>div ul li div.on {
  opacity: 1;
  box-shadow: none;
  border: 3px solid #ff2d55;
}

.types2>div ul p {
  position: absolute;
  bottom: -40px;
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
}

.types2 .none_opacity {
  opacity: 0.6;
}

/*payment_modal*/
.modal_box {
  position: absolute;
  left: 50%;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #151515;
}

.payment_modal_title {
  padding: 0 5%;
}

.payment_modal_title h1 {
  font-size: 18px;
}

.payment_modal_title svg {
  cursor: pointer;
}

/* confirm */
.confirm2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* agree */
.agree_box2 {
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.agree_box2 p {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  margin-left: 12px;
}

@media all and (max-width:500px) {
  .agree_box2 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border-radius: 8px;
    cursor: pointer;
  }

  .modal_bottom_btn {
    display: block;
    position: fixed;
    bottom: calc(env(safe-area-inset-bottom) + 11%);
    left: 0;
    width: 100% !important;
    z-index: 70;
    padding: 16px 18px !important;
    background: #282828;
  }

  .car_purpose_box {
    position: absolute;
    top: auto;
    left: auto;
    transform: translateY(0%);
    width: 100%;
    height: 100vh;
    padding: 32px 0;
    background: #282828;
    border-radius: 0px;
  }

  .car_purpose_title {
    padding: 0 18px;
  }

  .car_purpose_title h1 {
    font-size: 18px;
  }

  .car_purpose_title svg {
    cursor: pointer;
  }

  /*space_3*/
  .type_choice {
    height: 100vh;
  }

  .types2 {
    width: 100%;
    margin-top: 32px;
  }

  .types2>div {
    padding: 0 18px;
  }

  .types2>p {
    margin-bottom: 20px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
  }

  .types2>div ul {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 6%;
  }

  .types2>div ul li {
    position: relative;
    width: 76px;
    height: 76px;
    border-radius: 30%;
    margin-bottom: 50px;
  }

  .types2>div ul li:nth-child(3n + 2) {
    margin-left: auto;
    margin-right: auto;
  }

  .types2>div ul li:nth-child(3n + 3) {
    margin-left: auto;
  }

  .types2>div ul li span {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #FF1150 inset;
    background: linear-gradient(270deg, rgba(255, 17, 80, 0.6) 3.44%, rgba(255, 106, 134, 0.6) 100%);
  }

  .types2>div ul p {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
  }
}

/*content_scroll_motion*/
.fade-up2 {
  animation: fade-up 0.6s ease;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }

  10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

/* .types2 > div ul li div.on {
    background: rgba(0, 0, 0, 0.5);
  } */
.disableTrue {
  opacity: 0.3;
}

.disableTrue:hover {
  opacity: 0.3;
}

.gowork {
  background: url('/media/img/curation/gowork.png') no-repeat center 0;
  background-size: cover;
}

.gohome {
  background: url('/media/img/curation/gohome.png') no-repeat center 0;
  background-size: cover;
}

.short_drive {
  background: url('/media/img/curation/short_drive.png') no-repeat center 0;
  background-size: cover;
}

.long_drive {
  background: url('/media/img/curation/long_drive.png') no-repeat center 0;
  background-size: cover;
}

.working {
  background: url('/media/img/curation/working.png') no-repeat center 0;
  background-size: cover;
}

.routain {
  background: url('/media/img/curation/routain.png') no-repeat center 0;
  background-size: cover;
}

.travel {
  background: url('/media/img/curation/travel.png') no-repeat center 0;
  background-size: cover;
}
</style>
